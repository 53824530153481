// saurabh or thita
export const LINKEDIN_URL =
  "https://www.linkedin.com/in/saurabh-prajapati-08b41915b/";
export const FACEBOOK_URL =
  "https://www.linkedin.com/in/saurabh-prajapati-08b41915b/";
export const TWITTER_URL =
  "https://www.linkedin.com/in/saurabh-prajapati-08b41915b/";
export const INSTAGRAM_URL =
  "https://www.linkedin.com/in/saurabh-prajapati-08b41915b/";
export const SKYPE_URL =
  "https://www.linkedin.com/in/saurabh-prajapati-08b41915b/";
export const YOUTUBE_URL = "https://www.youtube.com/@thitashorts";
